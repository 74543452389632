<template>
  <div>
    <b-card style="bg-cover">
      <div class="profile">
        <div class="profile-header">
          <!-- BEGIN profile-header-cover -->
          <b-jumbotron>
            <div class="profile-header-img b-avatar rounded mb-1">
              <img
                style="width: 100px; height: 100px"
                :src="user.picture"
                alt=""
                class="img-circle avatar"
              />
            </div>
          </b-jumbotron>
          <!-- END profile-header-cover -->
          <!-- BEGIN profile-header-content -->
          <div class="profile-header-content">
            <!-- BEGIN profile-header-img -->
            <!-- BEGIN profile-header-info -->
            <div class="profile-header-info">
              <div>
                <h4 class="text-dark">
                  {{ user.lastname }} {{ user.firstname }} <br />
                  <small>{{ user.email }}</small>
                </h4>
              </div>

              <div class="text-right">
                <b-link
                  :to="'/admin/edit-profile/' + user.id"
                  class="btn btn-primary"
                >
                  Editer le profil
                </b-link>
              </div>
            </div>
            <!-- END profile-header-info -->
          </div>
          <!-- END profile-header-content -->
          <!-- BEGIN profile-header-tab -->

          <!-- END profile-header-tab -->
        </div>

        <div class="mt-1 ml-4">
          <div>
            <b-tabs content-class="mt-3">
              <b-tab title="Informations personnelles" active
                ><b-row class="">
                  <b-col md="5">
                    <p><strong>Nom : </strong> {{ user.firstname }}</p>
                    <p><strong>Prénom (s) : </strong> {{ user.lastname }}</p>
                  </b-col>
                  <b-col md="5">
                    <p><strong>Adresse : </strong> {{ user.address }}</p>
                    <p>
                      <strong>Situation matrimoniale : </strong>
                      {{ user.marital_status }}
                    </p>
                  </b-col>
                </b-row></b-tab
              >
              <b-tab title="Informations du compte"
                ><b-row class="">
                  <b-col md="5">
                    <p><strong>E-mail : </strong> {{ user.email }}</p>
                    <p>
                      <strong>Fonction : </strong> {{ fonctionName }} (
                      {{ serviceName }} )
                    </p>
                    
                    <p v-if="user.contract">
                      <strong>Type de contrat : </strong> {{ user.contract.name }} 
                    </p>
                  </b-col>
                  <b-col md="5">
                    <p>
                      <strong>Role : </strong>
                      {{ roleName }}
                    </p>
                    <p><strong>Téléphone : </strong> {{ user.telephone }}</p>
                  </b-col>
                </b-row></b-tab
              >
            </b-tabs>
          </div>
        </div>
      </div>
    </b-card>

    <!--########## Statistiques du collaborateur ##########-->
    <b-card>
      <h3>Statistiques du collaborateur</h3>
      <b-tabs content-class="mt-2">
        <!--########## Tâches en cours ##########-->
        <b-tab title="Tâches complétées" active>
          <h4 class="mb-2">
            Total :
            <b-badge variant="success">{{ completed_tasks.length }}</b-badge>
          </h4>
          <b-table
            no-border-collapse
            ref="refUserStats"
            responsive
            class="position-relative"
            show-empty
            empty-text="Pas de statistiques disponibles"
            :items="completed_tasks"
            :fields="stats"
          >
          </b-table>
        </b-tab>

        <!--########## Tâches en cours ##########-->
        <b-tab title="Tâches en cours">
          <h4 class="mb-2">
            Total :
            <b-badge variant="warning">{{ ongoing_tasks.length }}</b-badge>
          </h4>
          <b-table
            no-border-collapse
            ref="refUserStats"
            responsive
            class="position-relative"
            show-empty
            empty-text="Pas de statistiques disponibles"
            :items="ongoing_tasks"
            :fields="stats"
          >
          </b-table>
        </b-tab>

        <!--########## Tâches en retard ##########-->
        <b-tab title="Tâches en retard">
          <h4 class="mb-2">
            Total :
            <b-badge variant="danger">{{ delayed_tasks.length }}</b-badge>
          </h4>
          <b-table
            no-border-collapse
            ref="refUserStats"
            responsive
            class="position-relative"
            show-empty
            empty-text="Pas de statistiques disponibles"
            :items="delayed_tasks"
            :fields="stats"
          >
          </b-table>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      avatar: require("@/assets/images/avatars/13.png"),
      username: "John Doe",
      designation: "UI/UX Designer",
      coverImg: require("@/assets/images/profile/user-uploads/timeline.jpg"),
      perPage: 10,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      stats: [
        { key: "name", label: "Tâche", sortable: true },
        { key: "start_date", label: "Date de début", sortable: true },
        { key: "end_date", label: "Date de fin", sortable: true },
        // { key: "duration", label: "Durée", sortable: true },
        // { key: "action", label: "Action" },
      ],
      items: [],
    };
  },

  computed: {
    roleName() {
      return this.user.role?.name;
    },
    fonctionName() {
      return this.user.fonction?.name;
    },
    serviceName() {
      return this.user.service?.name;
    },
    enterpriseName() {
      return this.user.enterprise?.name;
    },

    completed_tasks() {
      return this.user_stats.finished_tasks;
    },

    ongoing_tasks() {
      return this.user_stats.ongoing_tasks;
    },

    delayed_tasks() {
      return this.user_stats.delayed_tasks;
    },

    ...mapState("auth", ["users", "user", "user_stats"]),
  },

  mounted() {
    this.showUser(this.$route.params.id);
    this.showUserStats(this.$route.params.id).then(() => {});
  },

  methods: {
    ...mapActions("auth", ["showUser", "showUserStats"]),
  },
};
</script>

<style>
.cadre {
  margin-left: 2%;
  margin-right: 2%;
}
.but {
  text-align: right;
}
@media only screen and (max-width: 600px) {
  .bouton {
    margin-top: 2%;
  }
  .profil-cadre {
    margin: 0;
    padding: 0;
  }
  .picture {
    margin-bottom: 5%;
  }
  .but {
    text-align: center;
    margin-right: 20%;
  }
}

.profile-header {
  position: relative;
  overflow: hidden;
}

.profile-header .profile-header-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.profile-header .profile-header-cover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.profile-header .profile-header-content {
  color: #fff;
  padding: 25px;
}

.profile-header-img {
  float: left;
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
  z-index: 10;
  margin: 0 0 -20px;
  padding: 3px;
  border-radius: 4px;
  background: #fff;
}

.profile-header-img img {
  max-width: 100%;
}

.profile-header-info h4 {
  font-weight: 500;
  color: #fff;
}

.profile-header-img + .profile-header-info {
  margin-left: 140px;
}

.profile-header .profile-header-content,
.profile-header .profile-header-tab {
  position: relative;
}

.profile-header .profile-header-tab {
  background: #fff;
  list-style-type: none;
  margin: -10px 0 0;
  padding: 0 0 0 140px;
  white-space: nowrap;
  border-radius: 0;
}

.text-ellipsis,
.text-nowrap {
  white-space: nowrap !important;
}
</style>


<!-- Extensions recommandées -->
  Thunder client
  Error lens
  svg
  env syntaxe color
  commitizen